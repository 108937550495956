var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      _vm.user_score.listening_status ||
        _vm.user_score.reading_status ||
        _vm.user_score.writing_status ||
        _vm.user_score.speaking_status
    )?_c('div',{staticClass:"reading"},[_c('hr',{staticClass:"section"}),_c('h4',{staticStyle:{"margin-bottom":"20px"}},[_vm._v(" Official TOEFL® Performance Descriptors "),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"This tells you what your score for each section means, according to the official TOEFL® rubric from the ETS.","placement":"top"}},[_c('small',[_c('i',{staticClass:"fa fa-question-circle question-icon",attrs:{"data-v-8b099506":"","aria-hidden":"true"}})])])],1),_c('div',{staticClass:"score-table"},[_c('table',{staticClass:"table table-bordered performance_descriptor"},[_vm._m(0),_c('tbody',_vm._l((_vm.user_evaluation),function(item){return _c('tr',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.user_score[item.name + '_status'] === 'SCORING' ||
                _vm.user_score[item.name + '_status'] === 'DONE' ||
                item.score > 0
            ),expression:"\n              user_score[item.name + '_status'] === 'SCORING' ||\n                user_score[item.name + '_status'] === 'DONE' ||\n                item.score > 0\n            "}],key:item.name},[(
                _vm.user_score[item.name + '_status'] === 'SCORING' &&
                  item.score == 0
              )?[_c('td',[_c('b',{staticStyle:{"display":"block","margin-bottom":"10px"}},[_vm._v(_vm._s(_vm.L.capitalize(item.name)))]),(!_vm.examInfo.can_suggest && !_vm.isAdmin)?_c('el-button',{attrs:{"type":"warning","size":"mini"},on:{"click":function($event){item.name === 'speaking'
                      ? _vm.$emit('openWarningSpeaking')
                      : _vm.$parent.getAlert()}}},[_c('i',{staticClass:"fas fa-lock"})]):_vm._e()],1),_c('td',{staticClass:"score_level text-center"},[_vm._v("-")]),_c('td',{staticClass:"your_performance text-center"},[_vm._v("-")])]:[_c('td',[_c('b',[_vm._v(_vm._s(_vm.L.capitalize(item.name)))]),_c('h3',{staticClass:"score",domProps:{"innerHTML":_vm._s(item.score)}})]),_c('td',{staticClass:"score_level",domProps:{"innerHTML":_vm._s(item.level_desc)}}),_c('td',{staticClass:"your_performance",domProps:{"innerHTML":_vm._s(item.desc)}})]],2)}),0)])])]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticClass:"green-td nowrap",staticStyle:{"width":"100px"}},[_vm._v("Skills")]),_c('th',{staticClass:"green-td level"},[_vm._v("Level")]),_c('th',{staticClass:"green-td"},[_vm._v("Your Performance")])])])}]

export { render, staticRenderFns }