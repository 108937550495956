var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      _vm.user_score.listening_status ||
        _vm.user_score.reading_status ||
        _vm.user_score.writing_status ||
        _vm.user_score.speaking_status
    )?_c('div',{staticClass:"reading"},[_c('hr',{staticClass:"section"}),_c('h4',{staticStyle:{"margin-bottom":"20px"}},[_vm._v(" Official TOEFL® Performance Descriptors "),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"This tells you what your score for each section means, according to the official TOEFL® rubric from the ETS.","placement":"top"}},[_c('small',[_c('i',{staticClass:"fa fa-question-circle question-icon",attrs:{"data-v-8b099506":"","aria-hidden":"true"}})])])],1),_vm._l((_vm.user_evaluation),function(item){return _c('div',{key:item.name},[_c('table',{staticClass:"table table-bordered"},[_c('thead',[_c('tr',[_c('th',{staticClass:"green-td nowrap"},[_c('b',[_vm._v(_vm._s(_vm.L.capitalize(item.name)))])])])]),_c('tbody',[_c('tr',[_c('td',[(
                  _vm.user_score[item.name + '_status'] === 'SCORING' &&
                    item.score == 0
                )?[(!_vm.examInfo.can_suggest && !_vm.isAdmin)?_c('el-button',{staticStyle:{"width":"100%"},attrs:{"type":"warning"},on:{"click":function($event){return _vm.$parent.getAlert()}}},[_c('i',{staticClass:"fas fa-lock"})]):_vm._e()]:[_c('span',{staticClass:"score_level",domProps:{"innerHTML":_vm._s(item.level_desc)}})]],2)]),_c('tr',[_c('td',[(
                  _vm.user_score[item.name + '_status'] === 'SCORING' &&
                    item.score == 0
                )?[_c('span',[_vm._v("-")])]:[_c('span',{staticClass:"score_level text-left",domProps:{"innerHTML":_vm._s(item.desc)}})]],2)])])])])})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }