<template>
  <div>
    <h4>
      Listening:
      <span
        ><b style="color: #ff8920;">{{ score }}</b> ({{ raw }}/{{
          count
        }})</span
      >
    </h4>
    <div v-for="(passage, index, order) in list" :key="index">
      <h5>Passage {{ order + 1 }}</h5>
      <table class="table table-bordered" style="width:100%">
        <thead>
          <tr class="rnum_1">
            <th class="green-td">#</th>
            <th class="green-td">Your Answer</th>
            <th class="green-td">Correct Answer</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(answer, index) in passage" :key="answer.order">
            <td class="green-td">{{ index + 1 }}</td>
            <td>
              <el-tooltip
                v-if="answer.taken_time && answer.taken_time > 0"
                class="item"
                effect="dark"
                :content="showTime(answer.taken_time)"
                placement="top"
              >
                <a
                  :href="getResolve(answer.toefl_question_id)"
                  v-html="
                    getMyAnswer(
                      answer.user_answer,
                      answer.correct_answer,
                      answer.is_correct
                    )
                  "
                >
                </a>
              </el-tooltip>
              <a
                v-else
                :href="getResolve(answer.toefl_question_id)"
                v-html="
                  getMyAnswer(
                    answer.user_answer,
                    answer.correct_answer,
                    answer.is_correct
                  )
                "
              >
              </a>
            </td>
            <td>
              <a
                :href="getResolve(answer.toefl_question_id)"
                v-html="getCorrectAnswer(answer.correct_answer)"
              ></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import ToeflMixin from "@/mixins/toefl.js";

export default {
  components: {},

  mixins: [ToeflMixin],

  props: ["list", "score", "raw", "count"],
  data() {
    return {};
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {
    showTime(seconds) {
      let minute;
      let second;
      if (seconds && seconds > 0) {
        minute = Math.floor(seconds / 60);
        second = Math.floor(seconds) - minute * 60;
      } else {
        minute = 0;
        second = 0;
      }
      // 让个位数的时间正常显示
      if (minute <= 9) minute = "0" + minute;
      if (second <= 9) second = "0" + second;
      return `花費時間： ${minute}:${second}`;
    },
    getResolve(question_id) {
      return this.$parent.getResolve(question_id);
    }
  }
};
</script>

<style scoped>
td a {
  display: inline-block;
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
}
td a:hover {
  background-color: #eee;
}
table.table td,
table.table th {
  padding: 0 5px !important;
  height: 45px;
  line-height: 45px;
}
@media screen and (min-width: 768px) {
  .table {
    position: relative;
    width: 100%;
    display: block;
  }
  thead {
    float: left;
  }
  tbody tr {
    display: inline-block;
  }
  th,
  td {
    height: 45px;
    padding: 0;
    line-height: 45px;
    display: block;
  }
  th {
    width: 150px;
  }
  .table td {
    min-width: 75px;
    /* max-width: 85px; */
  }
  td a {
    cursor: pointer;
  }
}
</style>
