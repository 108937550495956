<template>
  <div>
    <div
      class="reading"
      v-if="
        user_score.listening_status ||
          user_score.reading_status ||
          user_score.writing_status ||
          user_score.speaking_status
      "
    >
      <hr class="section" />
      <h4 style="margin-bottom: 20px">
        Official TOEFL® Performance Descriptors
        <el-tooltip
          class="item"
          effect="dark"
          content="This tells you what your score for each section means, according to the official TOEFL® rubric from the ETS."
          placement="top"
        >
          <small>
            <i
              data-v-8b099506=""
              aria-hidden="true"
              class="fa fa-question-circle question-icon"
            ></i>
          </small>
        </el-tooltip>
      </h4>
      <div class="score-table">
        <table class="table table-bordered performance_descriptor">
          <thead>
            <tr>
              <th class="green-td nowrap" style="width:100px">Skills</th>
              <th class="green-td level">Level</th>
              <th class="green-td">Your Performance</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in user_evaluation"
              :key="item.name"
              v-show="
                user_score[item.name + '_status'] === 'SCORING' ||
                  user_score[item.name + '_status'] === 'DONE' ||
                  item.score > 0
              "
            >
              <template
                v-if="
                  user_score[item.name + '_status'] === 'SCORING' &&
                    item.score == 0
                "
              >
                <td>
                  <b style="display:block;margin-bottom:10px">{{ L.capitalize(item.name) }}</b>
                  <el-button
                    v-if="!examInfo.can_suggest && !isAdmin"
                    type="warning"
                    @click="
                      item.name === 'speaking'
                        ? $emit('openWarningSpeaking')
                        : $parent.getAlert()
                    "
                    size="mini"
                  >
                    <i class="fas fa-lock"></i>
                  </el-button>
                </td>
                <td class="score_level text-center">-</td>
                <td class="your_performance text-center">-</td>
              </template>
              <template v-else>
                <td>
                  <b>{{ L.capitalize(item.name) }}</b>

                  <h3 class="score" v-html="item.score"></h3>
                </td>
                <td class="score_level" v-html="item.level_desc"></td>
                <td class="your_performance" v-html="item.desc"></td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import _ from "lodash";

export default {
  components: {},

  mixins: [],

  props: ["user_evaluation", "user_score", "examInfo", "isAdmin"],
  data() {
    return {
      L: _
    };
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {}
};
</script>

<style scoped>
hr.section {
  border: 0;
  border-bottom: 3px solid var(--themeColor);
  margin: 20px 0;
}
.level,
.nowrap {
  width: 150px;
}
.score {
  color: #ff8920;
}
.score-table {
  width: 100%;
  overflow: auto;
  line-height: 20px;
}
.table ul {
  margin-bottom: 0;
}
</style>
