<template>
  <div class="AI-Box  text-left">
    <div class="AI-Cover">
      <div class="AI-Header">
        <div class="header-logo">
          <b class="logo-color logo-title">AI</b>
          {{ content }}
        </div>
      </div>
      <div class="AI-content">
        <div>
          <h4 class="AI-Title" v-if="title">
            {{ title }}
          </h4>
          <div class="AI-Answer">
            <!-- <div class="answer-markdown" v-if="answer" v-html="answer"></div> -->
            <slot></slot>
          </div>
          <div v-show="showOther" class="AI-tips">
            Note: Automatically generated by AI, for reference only.
          </div>
        </div>
      </div>
      <!-- <div v-show="showOther" class="AI-other">
        <hr class="mt-0" />
        <div class="ai-icon reset-answer" @click="$emit('getAIAlertSpeaking', item)">
          <b class="logo-color">AI</b>
          Grading again
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user, instant } from "@ivy-way/material";
import $ from "jquery";
// import ACTList from "@/views/chatAI/apis/ChatAI.js";
// import MarkdownIt from "markdown-it";

export default {
  metaInfo() {},

  components: {},

  mixins: [],

  props: {
    questionId: {
      type: Number,
      default: null
    },
    content: {
      type: String,
      default: "Answer"
    },
    title: {
      type: String,
      default: null
    },
    prompt: {
      type: String,
      default: "Test"
    },
    text: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      showAIAnswer: false,
      interval: null,
      showOther: true
    };
  },
  computed: {
    ...mapState("AIChat", ["showAIChat"]),
    user() {
      return user;
    },
    instant() {
      return instant;
    }
  },
  watch: {
    questionId() {
      this.showAIAnswer = false;
    }
  },

  mounted() {},

  methods: {}
};
</script>

<style scoped>
.AI-Box {
  max-width: 100%;
}
.AI-button {
  font-weight: 700;
  font-size: 1.1rem;
  background-color: var(--themeColor);
  display: inline-block;
  padding: 0.5rem 2rem;
  border-radius: 0.5rem;
  background: linear-gradient(180deg, #a0e2ff, #fff);
  cursor: pointer;
  transition: background-image 0.5s ease;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.19);
  transition: box-shadow 0.3s, transform 0.3s;
}
.AI-button:hover {
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.3);
  transform: translateY(-1px);
}
.AI-Cover {
  border-radius: 1rem;
  -webkit-box-shadow: 0 2px 0.5rem 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 0.5rem 0 rgba(0, 0, 0, 0.08);
}
.header-logo {
  font-size: 1.1rem;
  border-radius: 1rem 1rem 0 0;
  font-weight: 700;
  padding: 1rem;
  background: linear-gradient(hsla(0, 0%, 100%, 0), #fff) padding-box,
    linear-gradient(180deg, #a0e2ff, #fff) border-box;
}

.logo-color {
  width: 100%;
  color: var(--themeColor);
  white-space: nowrap;
  background: linear-gradient(
    269deg,
    #fa12e3 1.83%,
    #7612fa 25.68%,
    #12d0fa 106.48%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
/* .logo-title {
  font-size: 1.4rem;
} */
.AI-content {
  padding: 0 1rem 1rem;
}
.AI-Answer {
  /* font-weight: 500; */
}
@keyframes gradientBackground {
  0% {
    opacity: 1;
  } /* 淡粉色 */
  100% {
    color: 0.5;
  } /* 深粉色 */
}
.type-point {
  font-size: 0.8rem;
  margin-left: 0.5rem;
  animation: gradientBackground 1s ease infinite;
}
.AI-tips {
  color: #9195a3;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.8rem;
  margin-top: 0.8rem;
}
.AI-other {
  padding: 0 1rem 1rem;
}
.ai-icon {
  height: 2rem;
  line-height: 2rem;
  font-size: 0.8rem;
  font-weight: 700;
  cursor: pointer;
  background-color: var(--themeColor);
  opacity: 0.9;
  border-radius: 1rem;
  color: white;
  display: inline-block;
  padding: 0 1rem;
  margin-right: 0.5rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19);
}
.ai-icon:hover {
  opacity: 1;
}
.reset-answer {
  background-color: #ccc;
  background: linear-gradient(180deg, #a0e2ff, #fff);
  color: black;
}
</style>
<style scoped>
/* ::v-deep .answer-markdown,
::v-deep .answer-markdown ol,
::v-deep .answer-markdown ul,
::v-deep .answer-markdown li {
  display: flex;
  flex-direction: column;
}
::v-deep .answer-markdown ol,
::v-deep .answer-markdown ul {
  padding-left: 0.5rem;
}
 */
::v-deep .answer-markdown p {
  margin: 0.5rem 0;
}
</style>
