<template>
  <div>
    <el-dialog title="Feedback" :visible.sync="showQuestion" width="70%">
      <el-form ref="form" :model="feedback">
        <el-form-item label="Type" class="text-left">
          <b>Writing</b>
        </el-form-item>
        <el-form-item label="Issues">
          <el-input
            type="textarea"
            v-model="description"
            :rows="7"
            :placeholder="$t('feedback.Details')"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="showQuestion = false">
            Cancel
          </el-button>
          <el-button type="primary" @click="postFeedback">
            Send the reason for the problem and return T-Coins to the student.
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <template>
      <h4>
        Writing:
        <span>
          <b style="color: #ff8920;"> {{ score }}</b>
        </span>
      </h4>
      <div class="tip">
        <div class="text">
          <router-link :to="{ name: 'Gradingsamples' }" target="_blank">
            <i class="el-icon-star-on"></i>
            {{ $t("toefl.balance.exp") }}
          </router-link>
        </div>
      </div>
      <table
        class="table table-bordered"
        v-for="(item, index) in list"
        :key="item.exam_question_id"
      >
        <thead>
          <tr>
            <th class="green-td view-question-tr">
              <a
                target="_blank"
                :href="getResolve(item.toefl_question_id)"
                class="view_question"
              >
                <b>Task {{ index + 1 }} <i class="far fa-eye"></i></b>
              </a>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <template v-if="item.user_answer && item.user_answer[0]">
                <div>
                  <div class="passage">
                    <div
                      class="text-left"
                      v-html="setText(item.user_answer[0])"
                    ></div>
                    <div class="text-right">
                      <b style="font-weight:500;">
                        <el-button
                          type="warning"
                          style="margin-right:10px"
                          size="mini"
                          @click="showQuestion = true"
                          v-show="!teacherScored"
                        >
                          <i class="fas fa-question-circle"></i> a problem with the answer
                        </el-button>
                        <span class="font-color">{{
                          getWordCount(item.user_answer[0])
                        }}</span>
                        Words
                      </b>
                    </div>
                    <div v-if="examInfo.can_suggest === 1 || teacherScored">
                      <div v-if="examInfo.suggest_type === 'outline'">
                        <el-divider content-position="left">
                          <b>{{ $t("GradingSamples.Simple Grading") }}</b>
                        </el-divider>
                      </div>
                      <div v-if="examInfo.can_score === 1 && examInfo.suggest_type === 'detail'">
                        <el-divider content-position="left">
                          <b class="vip">
                            <el-tag type="warning" effect="plain">
                              <i class="fas fa-chess-queen"></i>
                              VIP
                            </el-tag></b
                          ><b>{{
                            $t("GradingSamples.VIP Grading")
                          }}</b></el-divider
                        >
                      </div>
                    </div>
                    <div>
                      <div
                        class="col-sm-12 text-left d-flex align-items-center"
                        v-if="examInfo.can_score === 1 || teacherScored"
                      >
                        <b class="score-title">Response Score:&nbsp;</b>
                        <GradeLevelSelector
                          section="writing"
                          :value="item.score"
                          @change="
                            score =>
                              setAndAutoSaveScore(
                                index,
                                `writing_${item.toefl_question_type}`,
                                score
                              )
                          "
                        />
                      </div>
                      <SubScoreTable
                        v-if="
                          (examInfo.can_score === 1 || teacherScored) &&
                            commentCategories[
                              `writing_${item.toefl_question_type}`
                            ]
                        "
                        :value="
                          (item.answer_score_detail || {}).detail_score_comments
                        "
                        :testCategory="`writing_${item.toefl_question_type}`"
                        :hideSubComment="hideSubComment()"
                        :testCategories="
                          commentCategories[
                            `writing_${item.toefl_question_type}`
                          ]
                        "
                        @change="
                          subScores =>
                            setSubScores(
                              index,
                              `writing_${item.toefl_question_type}`,
                              subScores
                            )
                        "
                      />
                    </div>
                    <div class="col-sm-12 text-left">
                      <div v-if="examInfo.can_suggest === 1 || teacherScored">
                        <div class="overall-comments score-title">
                          <b>Overall Comments:</b>
                        </div>
                        <el-input
                          type="textarea"
                          :rows="8"
                          v-model="item.suggest"
                          @change="leaveAlert"
                        >
                        </el-input>
                      </div>
                    </div>
                    <div
                      class="col-sm-12 text-left mt-3"
                      v-if="
                        (examInfo.can_suggest === 1 &&
                          examInfo.suggest_type === 'detail') ||
                          teacherScored
                      "
                    >
                      <el-input
                        v-model="
                          (item.answer_score_detail || {}).overall_comment_url
                        "
                      >
                        <template slot="prepend">
                          <i class="fas fa-file-alt"></i> <b>Doc URL:</b>
                        </template>
                      </el-input>
                    </div>
                  </div>
                </div>
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import GradeLevelSelector from "@/views/toefl/grading/sections/GradeLevelSelector";
import SubScoreTable from "@/views/toefl/grading/sections/SubScoreTable";
import TOEFL from "@/apis/toefl";

export default {
  components: {
    GradeLevelSelector,
    SubScoreTable
  },

  mixins: [],

  props: ["list", "score", "examInfo", "commentCategories"],
  data() {
    return {
      score_list: [1, 2, 3, 4, 5],
      notes: false,
      textarea: "",
      showQuestion: false,
      feedback: {},
      description: ""
    };
  },
  computed: {
    ...mapState("user", ["profile"]),
    teacherScored() {
      return this.examInfo.can_score === 0;
    }
  },
  watch: {
    list() {
      this.leaveAlert();
    }
  },

  async mounted() {
    await this.getProfile();
    // if(!this.examInfo.suggest_type) {
    //   this.examInfo.suggest_type ="detail";
    // }
  },

  methods: {
    hideSubComment() {
      let hideSubComment = false;
      if (this.examInfo.can_score === 1 && this.examInfo.suggest_type !== "detail") {
        hideSubComment = true;
      }
      console.log(hideSubComment);
      return hideSubComment;
    },
    leaveAlert() {
      window.addEventListener("beforeunload", event => {
        event.preventDefault();
        event.returnValue = "";
      });
    },
    async postFeedback() {
      await TOEFL.postFeedback(this.$route.query.id, {
        description: this.description
      });
      this.showQuestion = false;
      this.$message({
        message:"Feedback success！Thanks♪(･ω･)ﾉ",
        type: "success"
      });
      this.$router.go(0);
    },
    getWordCount(string) {
      let word = 0;
      let count = 0;
      if (string == "" || string == undefined || string == null) {
        count = 0;
      } else {
        for (var i = 0; i < string.length; i++) {
          if (string[i] == " ") {
            word = 0;
          } else if (word == 0) {
            word = 1;
            count++;
          }
        }
      }
      return count;
    },
    getResolve(question_id) {
      return this.$parent.getResolve(question_id);
    },
    setText(text) {
      return text.replace(/\n/g, "<br />");
    },
    setAndAutoSaveScore(index, testType, score) {
      this.$emit("setTestScore", {
        index,
        payload: {
          score,
          answer_score_detail: {
            ...this.list[index].answer_score_detail,
            comment_type: testType
          }
        }
      });
      this.$emit("setScore", { index, score });
    },
    setSubScores(index, testType, subScores) {
      this.$emit("setTestScore", {
        index,
        payload: {
          answer_score_detail: {
            ...this.list[index].answer_score_detail,
            comment_type: testType,
            detail_score_comments: subScores
          }
        }
      });
    },
    ...mapActions("user", ["getProfile"])
  }
};
</script>

<style scoped>
.green-td {
  position: relative;
}

.view_question {
  color: #fff !important;
  text-decoration: none;
}

.view-question-tr:hover {
  opacity: 0.7;
}

.view_question:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
td a {
  cursor: pointer;
}
.essay {
  border: 1px solid #ebeef5;
  margin: 20px 0;
  padding: 20px;
  border-radius: 4px;
}
.essay .passage {
  min-height: 150px;
}
.essay .side-bar {
  height: 100%;
  padding: 0 20px;
}
.essay .essay-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: none;
  border-right: 1px solid rgba(0, 0, 0, 0.125);
}
.passage {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.vip {
  color: #f0ad4e;
}
.vip >>> .el-tag {
  margin-right: 8px;
  height: 20px;
  line-height: 18px;
  padding: 0 4px;
}
.passage >>> .cke_contents {
  min-height: 600px;
}
.tip {
  padding: 8px 16px;
  background-color: var(--themeColor) 40;
  border-radius: 4px;
  border-left: 5px solid var(--themeColor);
  margin: 0 0 20px 0;
}
.tip .text {
  font-size: 14px;
  font-weight: 500;
}
</style>
