<template>
  <div class="container">
    <div v-if="exam_info.exam_date">
      <div
        v-if="exam_info.is_finished && exam_info.is_finished == 1"
        style="margin-top:20px"
      >
        <el-alert
          :title="$t('courses.completed')"
          type="success"
          center
          show-icon
          :closable="false"
        >
        </el-alert>
      </div>
      <div v-else style="margin-top:20px">
        <el-alert
          :title="$t('courses.Incomplete')"
          type="error"
          center
          show-icon
          :closable="false"
        >
        </el-alert>
      </div>
      <Breadcrumb :customBreadcrumbList="[{ name: 'ToeflTestResults' }]">
        <template slot="lastPage">
          {{ exam_info.exam_title }}
        </template>
      </Breadcrumb>
      <div class="row sat">
        <div class="col-md-5 col-sm-12 sat_left">
          <p>TOEFL</p>
        </div>
        <div class="col-md-7 col-sm-12 sat_right">
          <div>
            <ul>
              <li>
                Name:
                <b>
                  <router-link
                    :to="
                      isAdmin
                        ? {
                            name: 'ProfileForAdmin',
                            params: { id: exam_info.student.id }
                          }
                        : { name: 'Profile' }
                    "
                  >
                    {{
                      `${exam_info.student.first_name} ${exam_info.student.last_name}`
                    }}
                  </router-link>
                </b>
                &nbsp;
                <template
                  v-if="
                    (exam_info.toefl_type === 'App\\Entities\\Toefl' ||
                      exam_info.toefl_type ===
                        'App\\Entities\\ToeflQuickMock') &&
                      exam_info.can_suggest == 1 &&
                      exam_info.is_scored == 1
                  "
                >
                  <router-link
                    v-if="exam_info.print_pdf == 1"
                    style="color: white !important;margin-left:10px"
                    :to="{
                      path: '/result/pdf',
                      query: { id: user_exam_id }
                    }"
                  >
                    <el-button type="success" size="mini">{{
                      $t("button.viewPdfReport")
                    }}</el-button></router-link
                  >
                  <router-link
                    v-else
                    style="color: white !important;margin-left:10px"
                    :to="{
                      path: '/scorereport',
                      query: { id: user_exam_id }
                    }"
                  >
                    <el-button type="success" size="mini">
                      {{ $t("toefl.PDF.DownloadPDF") }}
                    </el-button>
                  </router-link>
                </template>
              </li>
              <li>
                Test Name:
                <b>{{ exam_info.exam_title }}</b>
              </li>
              <li>
                Test Date: <b>{{ exam_info.exam_date }}</b>
              </li>
            </ul>
          </div>
          <div class="text-right img">
            <!-- <div class="logo">
              <h2>
                {{ CompanyName }}
              </h2>
            </div> -->
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-lg-6 user-scores">
          <score-card
            :user_score="user_score"
            :id="user_exam_id"
            :scoreDate="exam_info.exam_date"
            :reading="!isEmpty(reading_list)"
            :listening="!isEmpty(listening_list)"
            :speaking="!isEmpty(speaking_list)"
            :writing="!isEmpty(writing_list)"
          ></score-card>
        </div>
        <div class="col-md-12 col-lg-6">
          <best-score-card :user_score="user_score"></best-score-card>
        </div>
      </div>
      <template v-if="CompanyName === 'TestAdmit'">
        <hr class="section" />
        <div class="row" style="margin-top:20px">
          <div class="col-md-6">
            <a
              href="https://www.facebook.com/groups/2617774845204242"
              target="_blank"
              style="display:flex;justify-content:flex-end"
            >
              <img
                class="group-img"
                src="https://ivy-way-toefl.s3.ap-northeast-1.amazonaws.com/toefl/img/group-img.jpg"
                alt=""
              />
              <div class="facebook-group">
                <h4 class="group-title">
                  美國留學情報站｜教育，升學，移民等資訊交流及新聞分享
                </h4>
                <small><i class="fas fa-lock"></i> Private Group</small>
              </div>
            </a>
          </div>
          <div class="col-md-6">
            <div class="text-left group-title-right">
              <h3>{{ $t("toefl.facebookGroup") }}</h3>
            </div>
          </div>
        </div>
      </template>
      <ScoreTableM
        class="isPhone"
        :isAdmin="isAdmin"
        :user_evaluation="user_evaluation"
        :user_score="user_score"
        :examInfo="exam_info"
        @openWarningSpeaking="openWarningSpeaking"
      ></ScoreTableM>
      <ScoreTable
        class="isPc"
        :isAdmin="isAdmin"
        :user_evaluation="user_evaluation"
        :user_score="user_score"
        :examInfo="exam_info"
        @openWarningSpeaking="openWarningSpeaking"
      ></ScoreTable>
      <div v-if="!isEmpty(reading_list)">
        <hr class="section" />
        <reading
          :list="reading_list"
          :score="user_score.reading_score"
          :raw="user_score.reading_raw"
          :count="user_score.reading_question_count"
        ></reading>
        <Performance
          v-if="user_exam_tag_analysis && user_exam_tag_analysis.reading"
          section="Reading"
          :tags="user_exam_tag_analysis.reading"
          :passagesNumber="Object.keys(reading_list).length"
        ></Performance>
      </div>
      <div v-if="!isEmpty(listening_list)">
        <hr class="section" />
        <listening
          :list="listening_list"
          :score="user_score.listening_score"
          :raw="user_score.listening_raw"
          :count="user_score.listening_question_count"
        ></listening>
        <Performance
          v-if="user_exam_tag_analysis && user_exam_tag_analysis.listening"
          section="Listening"
          :tags="user_exam_tag_analysis.listening"
          :passagesNumber="Object.keys(listening_list).length"
        ></Performance>
      </div>
      <div v-if="!isEmpty(speaking_list)">
        <hr class="section" />
        <div v-if="isAdmin">
          <GradingSpeaking
            :score="user_score.speaking_score"
            @setScore="
              ({ index, score }) => setTestScore('speaking', index, { score })
            "
            @setTestScore="
              ({ index, payload }) => setTestScore('speaking', index, payload)
            "
            :list="speaking_list"
            :examInfo="exam_info"
            :commentCategories="commentCategories"
          />
        </div>
        <div v-else>
          <Speaking
            :score="user_score.speaking_score"
            :list="speaking_list"
            :examInfo="exam_info"
            :commentCategories="commentCategories"
            @openWarningSpeaking="openWarningSpeaking"
            @getAIAlertSpeaking="getAIAlertSpeaking"
          />
        </div>
        <Performance
          v-if="user_exam_tag_analysis && user_exam_tag_analysis.speaking"
          section="Speaking"
          :tags="user_exam_tag_analysis.speaking"
          :subScores="getSubScore('speaking_all')"
          :passagesNumber="Object.keys(speaking_list).length"
        ></Performance>
      </div>
      <div v-if="!isEmpty(writing_list)">
        <hr class="section" />
        <div v-if="isAdmin">
          <GradingWriting
            :score="user_score.writing_score"
            @setScore="
              ({ index, score }) => setTestScore('writing', index, { score })
            "
            @setTestScore="
              ({ index, payload }) => setTestScore('writing', index, payload)
            "
            :list="writing_list"
            :examInfo="exam_info"
            :commentCategories="commentCategories"
          />
        </div>
        <div v-else>
          <Writing
            :score="user_score.writing_score"
            :list="writing_list"
            :examInfo="exam_info"
            :commentCategories="commentCategories"
            @openWarningSpeaking="getAlert()"
            @getAIAlert="getAIAlert"
          />
        </div>
        <Performance
          v-if="
            user_exam_tag_analysis &&
              user_exam_tag_analysis.writing &&
              hasIntegratedQuestion
          "
          section="Writing_integrated"
          :tags="user_exam_tag_analysis.writing"
          :subScores="getSubScore('writing_integrated')"
          :passagesNumber="Object.keys(writing_list).length"
        />
        <Performance
          v-if="
            user_exam_tag_analysis &&
              user_exam_tag_analysis.writing &&
              hasIndependentQuestion
          "
          :hideTitle="hasIntegratedQuestion"
          section="Writing_independent"
          :tags="user_exam_tag_analysis.writing"
          :subScores="getSubScore('writing_independent')"
          :passagesNumber="Object.keys(writing_list).length"
        />
      </div>
      <div
        v-if="(!isEmpty(speaking_list) || !isEmpty(writing_list)) && isAdmin"
        style="margin-bottom:20px"
      >
        <hr class="section" />
        <div class="text-center">
          <el-button @click="saveTranscript()" type="success">
            Save
          </el-button>
          <el-button @click="saveTranscriptAndNotify()" type="success">
            Save and send email notifications
          </el-button>
        </div>
      </div>
      <el-dialog
        title="Tips"
        :visible.sync="warningSpeaking"
        :width="isPhone ? '90%' : '40%'"
        center
      >
        <el-alert
          title="Confirm that your voice can play normally and click Continue to unlock your achievements."
          type="warning"
          show-icon
          :closable="false"
        >
        </el-alert>
        <span slot="footer" class="dialog-footer">
          <el-button @click="warningSpeaking = false">Cancel</el-button>
          <el-button type="primary" @click="getTeacherScore">Continue</el-button>
        </span>
      </el-dialog>
      <Lock
        ref="lock"
        :userExamId="user_exam_id"
        :examInfo="exam_info"
        :pointPackages="pointPackages"
        @getAIContent="getAIContent"
      />
    </div>
    <div v-else>
      <div class="text-center">
        <el-alert
          style="width:200px;margin:30px auto"
          :title="$t('toefl.testLoading')"
          type="success"
          :closable="false"
          effect="dark"
        >
        </el-alert>
      </div>
    </div>
  </div>
</template>
<script>
import "./style/reportcard.css";
import { Decimal } from "decimal.js";
import Breadcrumb from "@/components/Breadcrumb";
import TOEFL from "@/apis/toefl";
import role from "@/mixins/role.js";
import _ from "lodash";
import ScoreCard from "./ScoreCard";
import BestScoreCard from "./BestScoreCard";
import ScoreTable from "./ScoreTable";
import ScoreTableM from "./ScoreTableM";
import Reading from "./Reading";
import Performance from "./performance/Index";
import Listening from "./Listening";
import Speaking from "./Speaking";
import Writing from "./Writing";
import GradingSpeaking from "@/views/toefl/grading/sections/Speaking";
import GradingWriting from "@/views/toefl/grading/sections/Writing";
import Lock from "./components/Lock";

export default {
  metaInfo() {
    return {
      title: "TOEFL Test Result - " + this.CompanyName
    };
  },

  components: {
    Breadcrumb,
    ScoreCard,
    BestScoreCard,
    ScoreTable,
    ScoreTableM,
    Reading,
    Performance,
    Listening,
    Speaking,
    Writing,
    GradingSpeaking,
    GradingWriting,
    Lock
  },

  mixins: [role],

  props: [],
  data() {
    return {
      nowAIItem: null,
      AIType: null,
      commentCategories: {},
      user_evaluation: {},
      user_score: {},
      topic: [],
      exam_info: { student: { first_name: "", last_name: "" } },
      pointPackages: [],
      updateAnswers: [],
      timer: null,
      speaking_list: null,
      writing_list: null,
      user_exam_tag_analysis: null,
      writing_speaking_analysis: null,
      warningSpeaking: false,
      isPhone: false
    };
  },
  computed: {
    isAdmin() {
      let isAdmin = this.isRoleAdmin();
      return isAdmin;
    },
    user_exam_id() {
      return this.$route.query.id;
    },
    reading_list() {
      return _.groupBy(
        _.filter(this.topic, ["skill_name", "reading"]),
        "passage"
      );
    },
    listening_list() {
      return _.groupBy(
        _.filter(this.topic, ["skill_name", "listening"]),
        "passage"
      );
    },
    canSave() {
      let scoreCount = 0;
      if (this.updateAnswers.length > 0) {
        this.updateAnswers.forEach(item => {
          if (item.score > 0) {
            scoreCount++;
          }
          if (item.suggest != "") {
            scoreCount++;
          }
        });
      }
      return scoreCount > 0;
    },
    hasIntegratedQuestion() {
      return this.writing_list.some(
        ({ toefl_question_type }) => toefl_question_type === "integrated"
      );
    },
    hasIndependentQuestion() {
      return this.writing_list.some(
        ({ toefl_question_type }) => toefl_question_type === "independent"
      );
    }
  },

  mounted() {
    this.getTranscript();
    this.getPointPackage();
    this.detectWidthIsPhone();
    window.addEventListener("resize", this.detectWidthIsPhone);
  },
  destroyed() {
    window.removeEventListener("resize", this.detectWidthIsPhone);
  },
  methods: {
    detectWidthIsPhone() {
      this.isPhone = document.body.clientWidth <= 1000;
    },
    async sendEmail(user_exam_id) {
      try {
        await this.$confirm("Do you want to send a notification email?", "Saved!", {
          confirmButtonText: "Yes",
          cancelButtonText: "No"
        });
        await TOEFL.sendEmail(user_exam_id);
        this.$message({
          message: "Success!",
          type: "success"
        });
      } catch (e) {
        // do nothing
      }
    },
    getSubScore(type) {
      const computeTestScoreCountAndScore = (scores, category) => {
        let totalScore = 0;
        let scoreCount = 0;
        scores.map(({ answer_score_detail }) => {
          const subScore = (
            answer_score_detail?.detail_score_comments || []
          ).find(
            ({ comment_category_id }) => comment_category_id === category.id
          );
          if (subScore) {
            scoreCount += 1;
            totalScore = Decimal.add(totalScore, subScore.score);
          }
        });
        return {
          totalScore,
          scoreCount
        };
      };
      return (this.commentCategories[type] || []).map(category => {
        let result = { totalScore: 0, scoreCount: 0 };
        switch (type) {
          case "speaking_all":
            result = computeTestScoreCountAndScore(
              this.speaking_list,
              category
            );
            break;
          case "writing_integrated":
          case "writing_independent":
            result = computeTestScoreCountAndScore(this.writing_list, category);
            break;
          default:
        }
        const { totalScore, scoreCount } = result;

        const {
          writing_speaking_analysis,
          writing_speaking_user_analysis
        } = this.writing_speaking_analysis;
        const totalAnalysis = Object.keys(writing_speaking_analysis)
          .map(key => writing_speaking_analysis[key])
          .find(
            ({ comment_category_id }) => comment_category_id === category.id
          );
        const myAnalysis = Object.keys(writing_speaking_user_analysis)
          .map(key => writing_speaking_analysis[key])
          .find(
            ({ comment_category_id }) => comment_category_id === category.id
          );

        return {
          ...category,
          totalScore: Number(totalScore),
          scoreCount,
          pastTotalScore: totalAnalysis?.total_score || 0,
          pastTotalTakenCount: totalAnalysis?.taken_number || 0,
          myTotalScore: myAnalysis?.total_score || 0,
          myTakenCount: myAnalysis?.taken_number || 0
        };
      });
    },
    getArr(arr) {
      arr.forEach(val => {
        let newVal = {
          id: val.user_exam_answer_id,
          score: val.score,
          suggest: val.suggest,
          answer_score_detail: val.answer_score_detail
            ? {
              comment_type: val.answer_score_detail.comment_type,
              detail_score_comments:
                  val.answer_score_detail?.detail_score_comments,
              overall_comment_url:
                  val.answer_score_detail?.overall_comment_url
            }
            : {}
        };
        this.updateAnswers.push(newVal);
      });
    },
    async saveTranscript(text = "") {
      this.updateAnswers = [];
      this.getArr(this.speaking_list);
      this.getArr(this.writing_list);
      if (this.canSave) {
        await TOEFL.updateTranscript(this.user_exam_id, {
          user_exam_answers: this.updateAnswers
        });
        this.$notify({
          title: text + "Success！",
          type: "success",
          position: "bottom-right",
          duration: "1000"
        });
      }
    },
    async saveTranscriptAndNotify() {
      await this.saveTranscript();
      this.sendEmail(this.user_exam_id);
    },
    openWarningSpeaking() {
      this.warningSpeaking = true;
      // this.$message({
      //   message: "Your score and feedback will be available soon. (Current queue: 1-3 business days for Pro users and 3-7 business days free users)",
      //   type: "warning"
      // });
    },
    getTeacherScore() {
      this.warningSpeaking = false;
      this.getAlert();
    },
    getAlert() {
      this.$refs.lock.getAlert();
      // this.$message({
      //   message: "Your score and feedback will be available soon. (Current queue: 1-3 business days for Pro users and 3-7 business days free users)",
      //   type: "warning"
      // });
    },
    getAIAlert(item) {
      this.nowAIItem = item;
      this.AIType = "writing-score";
      this.getAIContent();
    },
    getAIAlertSpeaking(item) {
      this.nowAIItem = item;
      this.AIType = "speaking-score";
      this.getAIContent();
    },
    async getAIContent() {
      const res = await TOEFL.getAIContent(this.nowAIItem.user_exam_answer_id, this.AIType);
      // this.$refs.lock.closeAlert();
      this.getTranscript();
    },
    async getTranscript() {
      const res = await TOEFL.getTranscript(this.user_exam_id);
      let analysisTags = { ...res.user_exam_tag_analysis };
      this.user_exam_tag_analysis = analysisTags;
      this.writing_speaking_analysis = res.writing_speaking_analysis;
      this.user_evaluation = res.user_evaluation;
      this.user_score = res.user_score;
      this.topic = res.topic;
      this.exam_info = res.exam_info;
      let speaking_list = _.filter(this.topic, ["skill_name", "speaking"]);
      speaking_list.forEach(element => {
        this.$set(element, "canPlay", true);
        this.$set(element, "width", 0);
        this.$set(element, "progress", 0);
        this.$set(element, "time", 60);
        this.$set(element, "speed", 1);
      });
      this.speaking_list = JSON.parse(JSON.stringify(speaking_list));
      let writing_list = _.filter(this.topic, ["skill_name", "writing"]);
      this.writing_list = JSON.parse(JSON.stringify(writing_list));

      let subScoresCategoriesPromises = [];
      if (this.speaking_list.length > 0) {
        subScoresCategoriesPromises.push(
          TOEFL.getSubScoreCategories("speaking_all")
        );
      }
      this.writing_list.forEach(({ toefl_question_type }) => {
        subScoresCategoriesPromises.push(
          TOEFL.getSubScoreCategories(`writing_${toefl_question_type}`)
        );
      });
      const categories = await Promise.all(subScoresCategoriesPromises);
      let commentCategories = {};
      categories.forEach(category => {
        const categoryType = category.comment_categories[0].type;
        commentCategories[categoryType] = category.comment_categories;
      });
      this.commentCategories = { ...commentCategories };
    },
    async getPointPackage() {
      const res = await TOEFL.getPointPackage(this.user_exam_id);
      if (res.point_packages) {
        if (res.point_packages.length > 0) {
          res.point_packages.forEach(val => {
            val["selected"] = false;
          });
          this.pointPackages = res.point_packages;
        }
      }
    },
    isEmpty(object) {
      return _.isEmpty(object);
    },
    getResolve(question_id) {
      const { href } = this.$router.resolve({
        name: "ToeflResolve",
        query: {
          user_exam_id: this.user_exam_id,
          question_id: question_id
        }
      });
      return href;
    },
    async setTestScore(type, targetIndex, payload) {
      let target = this[`${type}_list`].find(
        (_value, index) => index === targetIndex
      );
      Object.keys(payload).forEach(prop => {
        target[prop] = payload[prop];
      });
      const hasUpdateScore = Object.keys(payload).some(key => key === "score");
    },
    autoUpdate(afterUpdate = () => {}) {
      if (!this.timer && this.isAdmin) {
        this.timer = setTimeout(async () => {
          await this.saveTranscript();
          clearInterval(this.timer);
          this.timer = null;
          afterUpdate();
        }, 2000);
      }
    }
  }
};
</script>

<style scoped>
.container {
  padding-top: 20px;
}
.logo {
  color: var(--themeColor);
  display: flex;
  font-weight: 700;
  justify-content: flex-end;
}
.logo h2 {
  font-size: 40px;
  color: var(--themeColor);
  font-weight: 700;
}
.toefl-r {
  font-weight: 700;
  font-size: 22px;
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}
hr.section {
  border: 0;
  border-bottom: 3px solid var(--themeColor);
  margin: 20px 0;
}
.reportcard {
  padding: 20px;
}
.sat {
  margin-left: 0;
  margin-right: 0;
}
.sat_left > p:first-child {
  font-size: 120px;
  font-weight: 700;
  color: var(--themeColor);
  line-height: 120px;
  margin-bottom: 0px;
}
.tip {
  padding: 8px 16px;
  background-color: var(--themeColor) 40;
  border-radius: 4px;
  border-left: 5px solid var(--themeColor);
  margin: 0 0 20px 0;
}
.tip .text {
  font-size: 14px;
  font-weight: 500;
}

.facebook-group {
  padding: 0 20px;
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.facebook-group h4 {
  font-size: 20px;
}
.group-title-right h3 {
  font-size: 16px;
}
.facebook-group small {
  color: #65676b;
}
.group-img {
  display: inline-block;
  width: 80px;
  height: 80px;
  border-radius: 5px;
}
.group-title {
  font-weight: 700;
  display: inline-block;
}
.sat_right {
  display: flex;
  justify-content: space-between;
  padding: 0;
  padding-top: 10px;
}
::v-deep .sat_right .el-button {
  padding: 0 10px;
}

@media screen and (max-width: 1200px) {
  .user-scores {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 768px) {
  .sat_left > p:first-child {
    font-size: 60px;
    line-height: 60px;
    /* text-align: center; */
  }
  .sat_right {
    padding: 0 0 0 0;
    border: none;
  }
  .sat_right .img {
    display: none;
  }
  .sat_right ul li {
    font-size: 16px;
  }
  .user-scores {
    margin-bottom: 20px;
  }
  .sat_left {
    text-align: center;
  }
  .breadcrumb {
    display: none;
  }
  .facebook-group .group-title {
    font-size: 16px;
  }
  .group-title-right {
    display: none;
  }
  .isPhone {
    display: block;
  }
  .isPc {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .isPhone {
    display: none;
  }
  .isPc {
    display: block;
  }
}
</style>
