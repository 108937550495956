var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h4',[_vm._v(" Listening: "),_c('span',[_c('b',{staticStyle:{"color":"#ff8920"}},[_vm._v(_vm._s(_vm.score))]),_vm._v(" ("+_vm._s(_vm.raw)+"/"+_vm._s(_vm.count)+")")])]),_vm._l((_vm.list),function(passage,index,order){return _c('div',{key:index},[_c('h5',[_vm._v("Passage "+_vm._s(order + 1))]),_c('table',{staticClass:"table table-bordered",staticStyle:{"width":"100%"}},[_vm._m(0,true),_c('tbody',_vm._l((passage),function(answer,index){return _c('tr',{key:answer.order},[_c('td',{staticClass:"green-td"},[_vm._v(_vm._s(index + 1))]),_c('td',[(answer.taken_time && answer.taken_time > 0)?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.showTime(answer.taken_time),"placement":"top"}},[_c('a',{attrs:{"href":_vm.getResolve(answer.toefl_question_id)},domProps:{"innerHTML":_vm._s(
                  _vm.getMyAnswer(
                    answer.user_answer,
                    answer.correct_answer,
                    answer.is_correct
                  )
                )}})]):_c('a',{attrs:{"href":_vm.getResolve(answer.toefl_question_id)},domProps:{"innerHTML":_vm._s(
                _vm.getMyAnswer(
                  answer.user_answer,
                  answer.correct_answer,
                  answer.is_correct
                )
              )}})],1),_c('td',[_c('a',{attrs:{"href":_vm.getResolve(answer.toefl_question_id)},domProps:{"innerHTML":_vm._s(_vm.getCorrectAnswer(answer.correct_answer))}})])])}),0)])])})],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',{staticClass:"rnum_1"},[_c('th',{staticClass:"green-td"},[_vm._v("#")]),_c('th',{staticClass:"green-td"},[_vm._v("Your Answer")]),_c('th',{staticClass:"green-td"},[_vm._v("Correct Answer")])])])}]

export { render, staticRenderFns }